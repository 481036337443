import React from 'react';
import { navigate } from 'gatsby';
import { Grid, Row, Column, Text } from '@dls/web';
import styled from 'styled-components';
import ColorLogoMobile from '@dls/assets/images/logo_singtel@2x.png';
import QueryString from 'query-string';
import media from '../helpers/media';
import Footer from '../components/Footer';


const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  background: #ffffff;
`;
const HeaderWrapper = styled.div`
  border-top: solid 4px #ED1A3D;
  box-shadow: rgba(0,0,0,.2) 0px 0px 7px 0px;

  div:first-child {
    max-width: 1200px;
    padding-top: 0px;
    padding-bottom: 0px;
  }
`;

const BodyWrapper = styled(Grid)`
  max-width: 992px;
  ${media.sm`
    width: 100%;
  `}
`;

const LogoWrapper = styled(Column)`
  text-align: center;
  ${media.sm`
    text-align: left;
    padding: 0 0 0 20px;
  `}
`;

const LogoImage = styled.img`
  max-width: 120px;
  max-height: 120px;
`;

export default function NoProfile() {
  const [flowId, setFlowId] = React.useState(false);

  React.useEffect(() => {
    const qs = QueryString.parse(location.search);

    const flow = qs.flowId;
    setFlowId(flow);
  },[]);
  
  return (
    <PageWrapper>
      <HeaderWrapper>
        <Grid>
          <Row>
            <LogoWrapper xs={12} sm={4} md={4} style={{padding:'0px'}}>
              <LogoImage src={ColorLogoMobile} alt="Logo" />
            </LogoWrapper>
          </Row>
        </Grid>
      </HeaderWrapper>
      <BodyWrapper>
          <Row>
            <Column xs={12} sm={10}>
              <Text type="pageTitle" style={{padding:'60px 0 32px 0'}}>We can't find a profile that matches your Singpass details</Text>
              <Text type="header" style={{padding:'0 0 16px 0'}}>This might happen because</Text>
              <ul style={{padding:'0 0 0 20px'}}>
                <li style={{padding:'0 0 16px 20px'}}>
                  <Text>
                    Your bills are not registered under your NRIC/FIN or you may not have completed the required account verification.
                    {' '}
                    <Text type="link" href={`${window.location.origin}/sso/login/?flowId=${flowId}`}>
                      Log in with OnePass
                    </Text>
                  </Text>
                </li>
                <li style={{padding:'0 0 16px 20px'}}>
                  <Text>
                    You do not have a registered account.
                    {' '}
                    <Text type="link" href={`${window.location.origin}/sso/registration/?flowId=${flowId}`}>
                    Create a OnePass account
                    </Text>
                  </Text>
                </li>
              </ul>
              <Text>Need more information? 
                {' '}
                <Text type="link" href="https://www.singtel.com/personal/support/onepass">
                  View login FAQ
                </Text>
              </Text>
            </Column>
          </Row>
        </BodyWrapper>
      <Footer />
    </PageWrapper>
  );
}
